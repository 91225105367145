@import '../../index.scss';

.contentCoverImg {
    padding-top: 30px;
    padding-bottom: 30px;
    .coverImg {
        @extend .bgCover;
        height: 400px;
        background-size: contain;
    }

    .content {
        text-align: end;
        .title {
            text-align: end;
            color: $colorPrimary;
        }

        .text {
            margin-top: 30px;
        }
    }

    .left {
        text-align: start;
        .title {
            text-align: start;
        }
    }
}