@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap');
@import './assets/styles/variables';
@import './assets/styles/media';

@font-face {
  font-family: 'Open Sans', sans-serif;
   src: url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap')
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $colorWhite;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

p {
  font-size: 14px;
  color: $colorSemiBlack;
  @include media(lg-min) {
    font-size: 16px;
}
}

.topHeader {
  padding-top: 49px;
  @include media(md-min) {
    padding-top: 55.28px;
}
}

.colorSecondary {
  color: $colorSecondary !important;
}

.bgCover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.overlay {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.068));
  position: absolute;
}

.title {
  color: #537780;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

.titleDark {
  color: $colorSecondary;
  @extend .title;
}

.subTitle {
  color: $colorSemiBlack;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
}

.subTitleDark {
  color: $colorSection;
  @extend .subTitle;
}

.btn {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  &:hover {
    color: #fff;
  }
}