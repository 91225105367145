@import '../../index.scss';

.coverAbout {
    @extend .bgCover;
    height: 200px;
    margin-top: -1px;
    @include media(md-min) {
        margin-top: 0px;
       height: 300px;
    }
    @include media(lg-min) {
        height: 350px;
    }
    @include media(xl-min) {
        height: calc(100vh - 50px);
    }

    .overlay {
        @extend .coverAbout;
        background-image: none;
        background-color: #08272394;

        .contentCenter{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .titleCover {
                color: $colorSection;
                font-weight: 700;
                text-align: center;
                font-size: 30px;
                @include media(md-min) {
                    font-size: 40px;
                    
                 }
            }
        }
    }
}