//colors
$colorPrimary: #11999e;
$colorSecondary: #30e3ca;
$colorControl: #b9ebcc;
$colorSection: #e4f9f5;
$colorWhite: #ffffff;
$colorSemiGray: #c9d6df;
$colorSemiBlack: #353535;
// $colorSBlack2: #40514e;
$colorBlack: #222831;
$colorInfo: #4f98ca;
//fonts


// mode dark

$colorTextDark :  #a6b1b9;
$bgCardDark: #0E0E10;