@import '../../index.scss';

.cardServices {
    border-radius: 5px;
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    // border: 1px solid $colorSection;
    text-align: center;
    height: 100%;

    .icon {
        color: $colorSecondary;
        font-size: 40px;
    }

    .name {
        color: $colorPrimary;
        margin-top: 20px;
    }

    .text {
        margin-top: 10px;
    }
}

.cardServicesDark {
    @extend .cardServices;
    background-color: $bgCardDark;
    .text {
        margin-top: 10px;
        color: $colorTextDark;
    }
}


//circle Card ===============================================================

.circleCard {
    height: 100%;
    padding: 10px;
    // border: 1px solid $colorSection;
    // background-color: rgb(255, 255, 255);
    border-radius: 5px;
    // box-shadow: 1px -1px 6px 1px #00000012;
    .cirlce {
        width: 100%;
        height: 110px;
        display: flex;
        justify-content: center;

        img {
            width: 110px;
            height: 110px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .title {
        font-size: 22px;
    }

    .subTitle {
        font-size: 18px;
    }

    .text {
        text-align: center;
    }

    .line {
        width: 60%;
        height: 3px;
        background-color: $colorSecondary;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .btn {
        background-color: $colorSecondary;
        margin-top: 10px;
    }
}


.cardTeam {
    @extend .circleCard;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: $colorSection;

    // .cirlce {
    //     background-color: $colorWhite;
    //     img {
    //         border-radius: 50%;
    //         padding: 0;
    //         object-fit: cover;
    //     }
    // }

    .title {
        margin-top: 10px;
        color: $colorPrimary;
    }

    .subTitle {
        font-size: 14px;
    }

    .text {
        font-size: 14px;
    }
    .btn {
        background-color: #0e76a8;
    }
}

.cardTeamDark {
    @extend .cardTeam;
    background-color: $bgCardDark;
}



// tech card ==========================================================================
.TechCard {
    .contentImg {
        img {
            width: 50px;
            height: 50px;
            @include media(md-min) {
                width: 60px;
                height: 60px;
            }
        }
    }
    .name{
        font-size: 20px;
        text-align: center;
        font-weight: 700px;
        color: $colorBlack;
        margin-top: 10px;
    }
}