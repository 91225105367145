@import '../../index.scss';

.sectionContainer {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media(md-min) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.sectionContainer2 {
    @extend .sectionContainer;
    background-color: rgba(232, 249, 233, 0.64);
}

.sectionContainerDark {
    @extend .sectionContainer;
    background-color: #212429;

    .TechCard {
        .name {
            color: $colorTextDark;
        }
    }

    p {
        color: $colorTextDark;
    }

    .formContact {
        label {
            color: $colorTextDark !important;
        }
    }
}

.sectionContainerDark2 {
    @extend .sectionContainerDark;
    background-color: #171a1f;
}