@import '../../index.scss';

.footer {
    background-color: $colorBlack;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media(md-min) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .logo {
        width: 100%;
        max-width: 125px;
         @include media(md-min) {
            max-width: 150px;
         }
    }

    .copy {
        color: $colorSemiGray;
        font-size: 12px;

        @include media(md-min) {
            font-size: 14px;
            padding-left: 20px;
        }
    }

    .pharagrapFooter {
        color: $colorWhite;
        font-size: 12px;

        @include media(md-min) {
            font-size: 14px;
        }
    }

    .titleFooter {
        font-size: 16px;
        color: $colorWhite;
        font-weight: bold;
        margin-top: 40px;

        @include media(md-min) {
            font-size: 18px;
            margin-top: 0px;
        }
    }

    .borderBottom{
        width: 90px;
        height: 2px;
        background-color: $colorWhite;
        border-radius: 1px;

        @include media(md-min) {
            width: 100px;
        }
    }

    a {
        max-width: 100%;
        &:hover {
            .icons {
                background-color: $colorSecondary;
            }

            .pharagrapFooter {
                color: $colorSecondary;
            }
        }
    }

    .icons {
        width: 25px;
        height: 25px;
        background-color: $colorWhite;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon{
            font-size: 15px;
            color: $colorBlack;
        }
    }

    .footerSpace {
        display: flex;
        margin-top: 15px;
    }
}