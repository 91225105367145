@import '../../index.scss';

.coverPage {
    @extend .bgCover;
    width: 100%;
    height: 100vh;
    // height: calc(100vh - 44px);
    position: relative;
    // @include media(md-min) {
    //     height: calc(100vh - 50px);
    // }


    .overlayCover{
        @extend .overlay;

        .contentCenter {
            width: 100%;
            height: calc(100vh - 44px);
            display: flex;
            align-items: center;
            @include media(md-min) {
                height: calc(100vh - 50px);
            }
            .left {
                margin-top: -30%;
                width: 100%;
                @include media(md-min) {
                    width: 50%;
                    margin-top: 0%;
                }
                .titleCover {
                    color: $colorSection;
                    font-weight: 700;
                    font-size: 30px;
                    @include media(md-min) {
                        font-size: 40px;
                     }
                }
                .text {
                    color: $colorSemiGray;
                    margin-top: 10px;
                }
                .btn {
                    @extend .btn;
                    background-color: $colorPrimary;
                    margin-top: 20px;
                }
            }
        }
    }
}