@import '../../index.scss';

.contentHeader {
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.836);
	animation-name: fadeInDown;
    .contentLogo {
        .logoHeader {
            width: 150px;

            @include media(md-min) {
                width: 175px;
            }
        }

        h1 {
            color: #eaeaea;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            @include media(md-min) {
                font-size: 30px;
                line-height: 30px;
            }
        }
    }

    .contentMoon {
        background-color: $colorControl;
        border-radius: 50%;
        padding-top: 1px;
        padding-left: 3px;
        padding-right: 3px;
        padding-bottom: 2px;
        margin-right: 20px;
        @include media(md-min) {
            margin-right: 0;
        }
        .iconMoon{
            font-size: 16px;
            color: black;
            cursor: pointer;
        }
    }

    .contentMoonDark {
        @extend .contentMoon;
        background-color: transparent;
        .iconMoon {
            color: white;
        }
    }

   

    .iconMenu{
        color: #eaeaea;
        font-size: 25px;
        line-height: 25px;
    }

    .menuLarge{

        a {
            text-decoration: none;
        }

        p {
            margin-left: 20px;
            color: $colorSemiGray;
            cursor: pointer;
        }:hover {
            color:$colorWhite;
        }
    }

    .sliderBar{
        height: auto !important;
        margin-top: 48.5px !important;
        background-color: rgba(0, 0, 0, 0) !important;
        background-image: linear-gradient(rgba(0, 0, 0, 0.836), rgba(0, 0, 0, 0.747)) !important;
    }
}